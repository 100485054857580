import React from "react";
import styled from "styled-components";

const HeaderSection = styled.div`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-image: linear-gradient(to left, #1e5bb5, #062955);
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Header = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  color: #fff;
  margin-bottom: 12px;
  padding-top: 28px;
`;

const StyledImage = styled.img`
  width: 80px;
  height: 80px;
`;

const FadeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const InfoHeader = ({ card, active, phoneScreen }) => {
  const { title, icon } = card;
  return (
    <HeaderSection>
      <FadeWrapper active={!phoneScreen && active}>
        <div>
          <Header>{title}</Header>
        </div>
        <StyledImage src={icon} alt="icon" />
      </FadeWrapper>
    </HeaderSection>
  );
};

export default InfoHeader;
