import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionCollapse,
  AccordionToggle,
  Card,
} from "react-bootstrap";
import styled from "styled-components";
import AerodromesIcon from "../../assets/Regulations/regulations-aerodromes.svg";
import AirOperationsIcon from "../../assets/Regulations/regulations-air-operations.svg";
import AirTrafficIcon from "../../assets/Regulations/regulations-air-traffic-management.svg";
import AircrewIcon from "../../assets/Regulations/regulations-aircrew.svg";
import BasicRegulationsIcon from "../../assets/Regulations/regulations-basic-regulations.svg";
import EasaSeraIcon from "../../assets/Regulations/regulations-easa-sera.svg";
import ContinuingAirworthinessIcon from "../../assets/Regulations/regulations-initial-airworthiness.svg";
import Part21Icon from "../../assets/Regulations/regulations-part-21.svg";
import TcoIcon from "../../assets/Regulations/regulations-tco.svg";
import InfoHeader from "./InfoHeader";
import InfoSection from "./InfoSection";
import * as styles from "./InfoStyling.module.less";

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 110px;
  padding: 0;
  margin-bottom: 80px;
  @media screen and (max-width: 1025px) and (max-height: 770px) {
    padding: 0;
  }
  @media screen and (max-width: 770px) {
    padding: 0;
  }
  @media screen and (max-width: 450px) {
    flex-direction: column;
  }
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0.04px;
  color: #242d41;
  margin: 80px 0 20px;
  padding: 0 10%;
  text-align: center;
`;

const ButtonColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 40px;
  margin-top: 15px;
  flex-shrink: 0;
  position: sticky;
  top: 20px;
  @media screen and (max-width: 770px) {
    margin-right: 20px;
  }
`;

const StyledButton = styled.button`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${({ active }) => (active ? "#1e5bb5" : "#242d41")};
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  margin-bottom: 20px;
  &:focus {
    outline: none;
  }
  &:hover {
    color: #1e5bb5;
    text-decoration: underline;
  }
`;

const airOperationsCard = {
  title: "Air Operations",
  icon: AirOperationsIcon,
};

const camoCard = {
  title: "Continuing Airworthiness",
  icon: ContinuingAirworthinessIcon,
};

const aircrewCard = {
  title: "Aircrew",
  icon: AircrewIcon,
};

const basicCard = {
  title: "Basic Regulation and Occurence Reporting",
  icon: BasicRegulationsIcon,
};

const tcoCard = {
  title: "TCO Third Country Operators",
  icon: TcoIcon,
};

const seraCard = {
  title: "SERA Standarised European Rules of the Air",
  icon: EasaSeraIcon,
};

const airTrafficCard = {
  title: "Air Traffic Management/Air Navigation",
  icon: AirTrafficIcon,
};

const aerodromesCard = {
  title: "ADR Aerodromes",
  icon: AerodromesIcon,
};

const initialAirworthinessCard = {
  title: "Initial Airworthiness",
  icon: Part21Icon,
};

const RegulationsInfoSection = ({
  phoneScreen,
  setModalVisible,
  setModalLink,
}) => {
  const [activeItem, setActiveItem] = useState(null);
  const [active, setActive] = useState(false);
  const [localPhoneScreen, setLocalPhoneScreen] = useState(false);

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  const prevItem = usePrevious(activeItem);

  useEffect(() => {
    if (activeItem && prevItem && document.getElementById(activeItem)) {
      setTimeout(() => {
        document.getElementById(activeItem).scrollIntoView();
        window.scrollBy(0, -57);
      }, 300);
    }
  }, [activeItem, prevItem]);

  useEffect(() => {
    setActiveItem("dashboard");
  }, []);

  const handleItemChange = (item) => {
    setActive(true);
    setTimeout(() => {
      setActiveItem(item);
      setActive(false);
    }, 225);
  };

  useEffect(() => {
    if (phoneScreen) {
      setLocalPhoneScreen(true);
    }
  }, [phoneScreen]);

  return (
    <>
      {localPhoneScreen ? (
        <div className={styles.accordion}>
          <Title>
            Archive of our fully consolidated UK Regulations documents
          </Title>
          <Accordion>
            <AccordionToggle
              as={Card.Header}
              eventKey="0"
              id="air"
              onClick={() => setActiveItem("air")}
            >
              <InfoHeader card={airOperationsCard} />
            </AccordionToggle>
            <AccordionCollapse eventKey="0">
              <InfoSection
                phoneScreen={phoneScreen}
                activeItem="air"
                setModalLink={setModalLink}
              />
            </AccordionCollapse>
            <AccordionToggle
              as={Card.Header}
              eventKey="1"
              id="camo"
              onClick={() => setActiveItem("camo")}
            >
              <InfoHeader card={camoCard} />
            </AccordionToggle>
            <AccordionCollapse eventKey="1">
              <InfoSection
                phoneScreen={phoneScreen}
                activeItem="camo"
                setModalLink={setModalLink}
              />
            </AccordionCollapse>
            <AccordionToggle
              as={Card.Header}
              eventKey="2"
              id="aircrew"
              onClick={() => setActiveItem("aircrew")}
            >
              <InfoHeader card={aircrewCard} />
            </AccordionToggle>
            <AccordionCollapse eventKey="2">
              <InfoSection
                phoneScreen={phoneScreen}
                activeItem="aircrew"
                setModalLink={setModalLink}
              />
            </AccordionCollapse>
            <AccordionToggle
              as={Card.Header}
              eventKey="3"
              id="basic"
              onClick={() => setActiveItem("basic")}
            >
              <InfoHeader card={basicCard} />
            </AccordionToggle>
            <AccordionCollapse eventKey="3">
              <InfoSection
                phoneScreen={phoneScreen}
                activeItem="basic"
                setModalLink={setModalLink}
              />
            </AccordionCollapse>
            <AccordionToggle
              as={Card.Header}
              eventKey="4"
              id="tco"
              onClick={() => setActiveItem("tco")}
            >
              <InfoHeader card={tcoCard} />
            </AccordionToggle>
            <AccordionCollapse eventKey="4">
              <InfoSection
                phoneScreen={phoneScreen}
                activeItem="tco"
                setModalLink={setModalLink}
              />
            </AccordionCollapse>
            <AccordionToggle
              as={Card.Header}
              eventKey="5"
              id="initialairworthiness"
              onClick={() => setActiveItem("initialairworthiness")}
            >
              <InfoHeader card={initialAirworthinessCard} />
            </AccordionToggle>
            <AccordionCollapse eventKey="5">
              <InfoSection
                phoneScreen={phoneScreen}
                activeItem="initialairworthiness"
                setModalLink={setModalLink}
              />
            </AccordionCollapse>
            <AccordionToggle
              as={Card.Header}
              eventKey="6"
              id="airtraffic"
              onClick={() => setActiveItem("airtraffic")}
            >
              <InfoHeader card={airTrafficCard} />
            </AccordionToggle>
            <AccordionCollapse eventKey="6">
              <InfoSection
                phoneScreen={phoneScreen}
                activeItem="airtraffic"
                setModalLink={setModalLink}
              />
            </AccordionCollapse>
            <AccordionToggle
              as={Card.Header}
              eventKey="7"
              id="sera"
              onClick={() => setActiveItem("sera")}
            >
              <InfoHeader card={seraCard} />
            </AccordionToggle>
            <AccordionCollapse eventKey="7">
              <InfoSection
                phoneScreen={phoneScreen}
                activeItem="sera"
                setModalLink={setModalLink}
              />
            </AccordionCollapse>
            <AccordionToggle
              as={Card.Header}
              eventKey="8"
              id="aerodromes"
              onClick={() => setActiveItem("aerodromes")}
            >
              <InfoHeader card={aerodromesCard} />
            </AccordionToggle>
            <AccordionCollapse eventKey="8">
              <InfoSection
                phoneScreen={phoneScreen}
                activeItem="aerodromes"
                setModalLink={setModalLink}
              />
            </AccordionCollapse>
          </Accordion>
        </div>
      ) : (
        <>
          <Title>
            Archive of our fully consolidated UK Regulations documents
          </Title>
          <Wrapper>
            <ButtonColumn>
              <StyledButton
                type="button"
                onClick={() => handleItemChange("air")}
                active={activeItem === "air"}
              >
                Air Operations
              </StyledButton>
              <StyledButton
                type="button"
                onClick={() => handleItemChange("continuingairworthiness")}
                active={activeItem === "continuingairworthiness"}
              >
                Continuing Airworthiness
              </StyledButton>
              <StyledButton
                type="button"
                onClick={() => handleItemChange("aircrew")}
                active={activeItem === "aircrew"}
              >
                Aircrew
              </StyledButton>
              <StyledButton
                type="button"
                onClick={() => handleItemChange("basic")}
                active={activeItem === "basic"}
              >
                Basic Regulation and Occurence Reporting
              </StyledButton>
              <StyledButton
                type="button"
                onClick={() => handleItemChange("tco")}
                active={activeItem === "tco"}
              >
                TCO Third Country Operators
              </StyledButton>
              <StyledButton
                type="button"
                onClick={() => handleItemChange("easasera")}
                active={activeItem === "easasera"}
              >
                SERA Standarised European Rules of the Air
              </StyledButton>
              <StyledButton
                type="button"
                onClick={() => handleItemChange("airtraffic")}
                active={activeItem === "airtraffic"}
              >
                Air Traffic Management/Air Navigation
              </StyledButton>
              <StyledButton
                type="button"
                onClick={() => handleItemChange("adr")}
                active={activeItem === "adr"}
              >
                ADR Aerodromes
              </StyledButton>
              <StyledButton
                type="button"
                onClick={() => handleItemChange("initialairworthiness")}
                active={activeItem === "initialairworthiness"}
              >
                Initial Airworthiness
              </StyledButton>
            </ButtonColumn>
            <InfoSection
              activeItem={activeItem}
              active={active}
              phoneScreen={phoneScreen}
              setModalLink={setModalLink}
            />
          </Wrapper>
        </>
      )}
    </>
  );
};

export default RegulationsInfoSection;
