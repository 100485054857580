import React, { useState, useEffect } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Link } from "gatsby";
import styled from "styled-components";
import { AnimationOnScroll } from "react-animation-on-scroll";
import RegulationsImg from "../assets/Regulations/regulations.jpg";
import Tick from "../assets/icon-tick.svg";
import RegulationsInfoSection from "../components/Regulations/RegulationsInfoSection";
import getWindowSize from "../components/getWindowSize";
import Seo from "../components/SEO";
import Layout from "../components/layout";
import PageHeader from "../components/pageHeader";
import * as pageStyles from "./page.module.less";
import SignUpModal from "../components/signUpModal";

const ImageDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${({ right }) => (right ? "flex-start" : "flex-end")};
  padding-right: ${({ padding }) => (padding ? "0" : "25px")};
  padding-left: ${({ padding }) => (padding ? "25px" : "0")};
`;

const TextDiv = styled.div`
  margin-left: ${({ right, none }) => {
    if (right) return "125px";
    if (none) return "0";
    return "25px";
  }};
  @media screen and (max-width: 450px) {
    width: calc(100% - 20px);
    margin-left: 10px;
  }
`;

const TitleWithImage = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 1.17;
  color: #242d41;
  margin-bottom: 40px;
`;

const StyledTick = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 12px;
  flex-shrink: 0;
`;

const TickDiv = styled.div`
  margin-bottom: 20px;
  width: 480px;
  display: flex;
  div {
    font-size: 16px;
    line-height: 1.57;
    color: rgba(36, 45, 65, 0.7);
  }
  @media screen and (max-width: 1025px) {
    width: auto;
  }
`;

const Regulations = ({ data }) => {
  const { width, height } = getWindowSize();

  const [phoneScreen, setPhoneScreen] = useState(false);
  const [landscapeIpad, setLandscapeIpad] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalLink, setModalLink] = useState("");
  const [regulationSignUpSeen, setRegulationSignUpSeen] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("regulationsSignUpSeen")) {
      setRegulationSignUpSeen(true);
    }
  }, []);

  useEffect(() => {
    if (width < 451) {
      setPhoneScreen(true);
    }
    if (width < 1025 && height < 770) {
      setLandscapeIpad(true);
    }
  }, [width, height]);

  const handleLinkClick = (modalLink) => {
    if (regulationSignUpSeen && modalLink) {
      window.open(modalLink, "_blank");
    } else {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100vh";
      setModalLink(modalLink);
      setModalVisible(true);
    }
  };

  return (
    <Layout>
      <Seo
        title="UK Regulations"
        description="Everything you need to manage your operation, at your fingertips."
      />
      <PageHeader
        header="UK Regulations"
        subHeader={
          <>
            When the UK left the European Union on 31st December 2020 it adopted
            the existing applicable EASA regulations into UK law, but with a
            number of changes. Though the related changes have been posted
            elsewhere online, they were hard to find, with multiple individual
            documents often needing to be open and cross-referenced
            simultaneously. Please note that this page is no longer updated as
            the regulations can now be accessed via the{" "}
            <a href="https://www.caa.co.uk/uk-regulations/aviation-safety/basic-regulation-the-implementing-rules-and-uk-caa-amc-gm-cs/">
              UKCAA website
            </a>
            .
          </>
        }
      />
      <Container style={{ zIndex: 999 }}>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRow}`}
          style={{
            padding: phoneScreen || landscapeIpad ? "0 2%" : "0",
            marginTop: phoneScreen && "80px",
          }}
        >
          <form
            name="document"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="email" />
            <input type="hidden" name="doc" />
            <input type="hidden" name="checkbox" />
            <input type="hidden" name="form-name" value="document" />
          </form>
          <Col md={12} lg={5} style={{ padding: phoneScreen && "0" }}>
            <AnimationOnScroll
              animateOnce
              animateIn="animate__fadeInLeft"
              offset={0}
            >
              <TextDiv none>
                <TitleWithImage>
                  Making compliance quick and easy
                </TitleWithImage>
                <TickDiv>
                  <div>
                    For Centrik users, the current version of each consolidated
                    UK Regulations document can be used against all related
                    checklists and audits directly through the system itself,
                    making compliance quick and easy.
                  </div>
                </TickDiv>
                <TickDiv>
                  <h6>
                    Manage internal and external compliance against embedded
                    regulations using Centrik:
                  </h6>
                </TickDiv>

                <TickDiv>
                  <StyledTick src={Tick} alt="tick" />
                  <div>
                    Regulatory revisions monitored and updated by the Centrik
                    team
                  </div>
                </TickDiv>
                <TickDiv>
                  <StyledTick src={Tick} alt="tick" />
                  <div>
                    Side-by-side comparison of new vs existing revisions
                  </div>
                </TickDiv>
                <TickDiv>
                  <Link to="/contact">Talk to us today about access</Link>
                </TickDiv>
              </TextDiv>
            </AnimationOnScroll>
          </Col>
          <Col md={12} lg={7} style={{ marginTop: phoneScreen && "80px" }}>
            <AnimationOnScroll
              animateOnce
              animateIn="animate__fadeInRight"
              offset={0}
            >
              <ImageDiv right padding>
                <img src={RegulationsImg} alt="why" />
              </ImageDiv>
            </AnimationOnScroll>
          </Col>
        </Row>
      </Container>
      {modalVisible ? (
        <SignUpModal
          handleClose={() => {
            localStorage.setItem("regulationsSignUpSeen", "true");
            setRegulationSignUpSeen(true);
            setModalVisible(false);
            document.body.style.overflow = "auto";
            document.body.style.height = "100%";
          }}
          modalLink={modalLink}
        />
      ) : null}

      <Container>
        <AnimationOnScroll animateOnce animateIn="animate__fadeIn" offset={0}>
          <RegulationsInfoSection
            phoneScreen={phoneScreen}
            setModalLink={(value) => handleLinkClick(value)}
          />
        </AnimationOnScroll>
      </Container>
    </Layout>
  );
};
export default Regulations;
