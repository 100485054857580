import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AerodromesIcon from "../../assets/Regulations/regulations-aerodromes.svg";
import AirOperationsIcon from "../../assets/Regulations/regulations-air-operations.svg";
import AirTrafficIcon from "../../assets/Regulations/regulations-air-traffic-management.svg";
import AircrewIcon from "../../assets/Regulations/regulations-aircrew.svg";
import BasicRegulationsIcon from "../../assets/Regulations/regulations-basic-regulations.svg";
import EasaSeraIcon from "../../assets/Regulations/regulations-easa-sera.svg";
import ContinuingAirworthinessIcon from "../../assets/Regulations/regulations-initial-airworthiness.svg";
import Part21Icon from "../../assets/Regulations/regulations-part-21.svg";
import TcoIcon from "../../assets/Regulations/regulations-tco.svg";
import Tick from "../../assets/icon-tick.svg";
import DownloadIcon from "../../assets/Regulations/icon-button-download.png";
import InfoHeader from "./InfoHeader";

const AerodromesCard = {
  title: "ADR Aerodromes",
  key: "adr",
  icon: AerodromesIcon,
  text: [
    "For Centrik users, the current version of this document can be used against all related checklists and audits directly through the system itself, making compliance quick and easy.",
  ],
  buttons: [
    {
      text: "UK ADR Aerodromes Consolidated - October 2021",
      href: "https://storage.googleapis.com/assets.trustflight.io/resources/regulations/UK-ADR-Aerodromes-Consolidated-October-2021.pdf",
    },
  ],
};

const AircrewCard = {
  title: "Aircrew",
  key: "aircrew",
  icon: AircrewIcon,
  text: [
    "For Centrik users, the current version of this document can be used against all related checklists and audits directly through the system itself, making compliance quick and easy.",
  ],
  buttons: [
    {
      text: "UK Aircrew regulations – March 2022",
      href: "https://storage.googleapis.com/assets.trustflight.io/resources/regulations/UK-Aircrew-Regulations-Consolidated-March-2022.pdf",
    },
    {
      text: "UK Aircrew regulations – November 2021",
      href: "https://storage.googleapis.com/assets.trustflight.io/resources/regulations/UK-Aircrew-Regulations-Consolidated-201121.pdf",
    },
    {
      text: "UK Aircrew regulations – May 2021",
      href: "https://storage.googleapis.com/assets.trustflight.io/resources/regulations/UK-Aircrew-Regulations-Consolidated-May-2021.pdf",
    },
    {
      text: "UK Aircrew regulations – January 2021",
      href: "https://storage.googleapis.com/assets.trustflight.io/resources/regulations/UK-Aircrew-Regulations-Consolidated-Jan-2021-LE.pdf",
    },
  ],
};

const AirOperationsCard = {
  title: "Air Operations",
  key: "air",
  icon: AirOperationsIcon,
  text: [
    "For Centrik users, the current version of this document can be used against all related checklists and audits directly through the system itself, making compliance quick and easy.",
  ],
  buttons: [
    {
      text: "UK Air Operations regulations - November 2021",
      href: "https://storage.googleapis.com/assets.trustflight.io/resources/regulations/UK-Air-Operations-Regulations-Consolidated-201121.pdf",
    },
    {
      text: "UK Air Operations regulations – May 2021",
      href: "https://storage.googleapis.com/assets.trustflight.io/resources/regulations/UK-Air-Operations-Regulations-Consolidated-250521.pdf",
    },
    {
      text: "UK Air Operations regulations – March 2021",
      href: "https://storage.googleapis.com/assets.trustflight.io/resources/regulations/UK-Air-Operations-Regulations-Consolidated-120321.pdf",
    },
    {
      text: "UK Air Operations regulations – January 2021",
      href: "https://storage.googleapis.com/assets.trustflight.io/resources/regulations/UK-Air-Operations-Regulations-Consolidated-010121.pdf",
    },
  ],
};

const AirTrafficCard = {
  title: "Air Traffic Management/Air Navigation",
  key: "airtraffic",
  icon: AirTrafficIcon,
  text: [
    "For Centrik users, the current version of this document can be used against all related checklists and audits directly through the system itself, making compliance quick and easy.",
  ],
  buttons: [
    {
      text: "UK ATM ANS Regulations Consolidated - November 2021",
      href: "https://storage.googleapis.com/assets.trustflight.io/resources/regulations/UK-ATM-ANS-Regulations-Consolidated-201121.pdf",
    },
  ],
};

const BasicRegulationsCard = {
  title: "Basic Regulation and Occurence Reporting",
  key: "basic",
  icon: BasicRegulationsIcon,
  text: [
    "For Centrik users, the current version of this document can be used against all related checklists and audits directly through the system itself, making compliance quick and easy.",
  ],
  buttons: [
    {
      text: "UK Basic Regulation and Occurrence Reporting Consolidated - Jan 2021",
      href: "https://storage.googleapis.com/assets.trustflight.io/resources/regulations/UK-Basic-Regulation-and-Occurrence-Reporting-Consolidated-Jan-2021.pdf",
    },
  ],
};

const EasaSeraCard = {
  title: "SERA Standarised European Rules of the Air",
  key: "easasera",
  icon: EasaSeraIcon,
  text: [
    "For Centrik users, the current version of this document can be used against all related checklists and audits directly through the system itself, making compliance quick and easy.",
  ],
  buttons: [
    {
      text: "UK Air Operations regulations – January 2022",
      href: "https://storage.googleapis.com/assets.trustflight.io/resources/regulations/UK-SERA-Standard-European-Rules-of-the-Air-Consolidated-January-2022.pdf",
    },
    {
      text: "UK Air Operations regulations – October 2021",
      href: "https://storage.googleapis.com/assets.trustflight.io/resources/regulations/UK-SERA-Standard-European-Rules-of-the-Air-Consolidated-October-2021.pdf",
    },
  ],
};

const InitialAirworthinessCard = {
  title: "Initial Airworthiness",
  key: "initialairworthiness",
  icon: Part21Icon,
  text: [
    "For Centrik users, the current version of this document can be used against all related checklists and audits directly through the system itself, making compliance quick and easy.",
  ],
  buttons: [
    {
      text: "UK Regulation Part 21 Initial Airworthiness Annex I consolidated - March 2022",
      href: "https://storage.googleapis.com/assets.trustflight.io/resources/regulations/UK-Regulation-Part-21-Initial-Airworthiness-Annex-I-consolidated-March-2022.pdf",
    },
  ],
};

const ContinuingAirworthinessCard = {
  title: "Continuing Airworthiness",
  key: "continuingairworthiness",
  icon: ContinuingAirworthinessIcon,
  text: [
    "For Centrik users, the current version of this document can be used against all related checklists and audits directly through the system itself, making compliance quick and easy.",
  ],
  buttons: [
    {
      text: "UK Continuing Airworthiness regulations – November 2021",
      href: "https://storage.googleapis.com/assets.trustflight.io/resources/regulations/UK-Continuing-Airworthiness-Regulations-Consolidated-201121.pdf",
    },
    {
      text: "UK Continuing Airworthiness regulations – September 2021",
      href: "https://storage.googleapis.com/assets.trustflight.io/resources/regulations/UK-Continuing-Airworthiness-Regulations-Consolidated-Sep-2021.pdf",
    },
    {
      text: "UK Continuing Airworthiness regulations – January 2021",
      href: "https://storage.googleapis.com/assets.trustflight.io/resources/regulations/UK-Continuing-Airworthiness-Regulations-Consolidated-Jan-2021.pdf",
    },
  ],
};

const TcoCard = {
  title: "TCO Third Country Operators",
  key: "tco",
  icon: TcoIcon,
  text: [
    "For Centrik users, the current version of this document can be used against all related checklists and audits directly through the system itself, making compliance quick and easy.",
  ],
  buttons: [
    {
      text: "UK TCO Third Country Operators Consolidated - October 2021",
      href: "https://storage.googleapis.com/assets.trustflight.io/resources/regulations/UK-TCO-Third-Country-Operators-Consolidated-October-2021.pdf",
    },
  ],
};

const InfoSectionWrapper = styled.div`
  border-radius: 5px;
  box-shadow: 0 2px 15px 0 rgba(36, 45, 65, 0.1);
  margin-bottom: ${({ last }) => (last ? "100px" : 0)};
  width: 100%;
  @media screen and (max-width: 770px) {
    padding-bottom: 24px;
  }
`;

const ListSectionWrapper = styled.div`
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin: 24px;
  width: 100%;
  @media screen and (max-width: 450px) {
    margin: 0 24px;
    padding-top: 24px;
  }
`;

const StyledTick = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 12px;
  flex-shrink: 0;
`;

const PointWrapper = styled.div`
  display: flex;
  width: calc(100% - 48px);
`;

const TextWrapper = styled.span`
  display: flex;
  width: calc(100% - 48px);
  line-height: 1.57;
  font-size: 16px;
  color: rgba(36, 45, 65, 0.7);
`;

const TitleTextWrapper = styled.div`
  width: calc(100% - 48px);
  line-height: 1.57;
  color: rgba(36, 45, 65, 0.7);
  font-size: 16px;
  display: flex;
  flex-direction: column;
  margin-top: ${({ index }) => (index ? "40px" : "unset")};
`;

const PointText = styled.div`
  font-size: 16px;
  line-height: 1.57;
  color: rgba(36, 45, 65, 0.7);
  padding-bottom: 20px;
`;

const ContentWrapper = styled.div``;

const Button = styled.button`
  height: 40px;
  padding: 0 24px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 21px;
  border: solid 2px #1e5bb5;
  background-color: #1e5bb5;
  color: #fff;
  display: flex;
  align-items: center;
  margin-bottom: 48px;
  span {
    flex-shrink: 0;
    margin-right: 8px;
  }
  &:hover {
    box-shadow: rgb(196 200 209 / 40%) 0px 8px 16px 0px;
    transform: translateY(2px);
    transition: all 0.4s ease-out 0s;
  }
`;

const Download = styled.img`
  height: 16px;
  width: 16px;
  margin-right: 8px;
`;

const VersionsLink = styled.button`
  color: #1e5bb5;
  line-height: 1.8em;
  font-size: 16px;
  background-color: transparent;
  padding: 0;
  border: none;
  &:hover {
    text-decoration: underline;
    color: #1e5bb5;
  }
  &:focus {
    outline: none;
  }
  @media (max-width: 821px) {
    text-align: left;
  }
  @media (max-height: 769px) {
    width: 90%;
    text-align: left;
  }
`;

const InfoSection = ({
  activeItem,
  last,
  active,
  phoneScreen,
  setModalLink,
}) => {
  const [currentCard, setCurrentCard] = useState(AirOperationsCard);

  useEffect(() => {
    const cardArray = [
      AerodromesCard,
      AircrewCard,
      AirOperationsCard,
      AirTrafficCard,
      BasicRegulationsCard,
      EasaSeraCard,
      InitialAirworthinessCard,
      ContinuingAirworthinessCard,
      TcoCard,
    ];
    if (activeItem) {
      const newCard = cardArray.find((item) => item.key === activeItem);
      if (newCard) setCurrentCard(newCard);
    }
  }, [activeItem]);

  const renderList = () => {
    if (currentCard.text) {
      return currentCard.text.map((line, index) => {
        if (line.title) {
          return (
            <TitleTextWrapper key={index} index={index === 1}>
              {line.title}
              <br />
              <br />
              {line.points.map((point) => {
                return (
                  <PointWrapper>
                    <StyledTick src={Tick} alt="tick" />
                    <PointText>{point}</PointText>
                  </PointWrapper>
                );
              })}
            </TitleTextWrapper>
          );
        } else {
          return (
            <TextWrapper key={index}>
              {line}
              <br />
              <br />
            </TextWrapper>
          );
        }
      });
    }
  };

  const renderButtonsSection = (buttons) => {
    if (buttons.length === 0) {
      return null;
    }
    const localButtons = [...buttons];
    // latest version set as null as per request as all versions are now outdated. Can add back in once we get new documents
    const latestVersion = null;
    return (
      <div>
        {latestVersion && (
          <div>
            <Button
              variant="link"
              onClick={() => {
                setModalLink(latestVersion.href);
              }}
            >
              <Download src={DownloadIcon} alt="download" />
              {latestVersion.text}
            </Button>
          </div>
        )}
        {localButtons.length > 0 && (
          <div>
            <h5>Archive:</h5>
            {localButtons.map((version) => {
              return (
                <div>
                  <VersionsLink
                    type="button"
                    onClick={() => {
                      setModalLink(version.href);
                    }}
                  >
                    {version.text}
                  </VersionsLink>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  return (
    <InfoSectionWrapper last={last}>
      {!phoneScreen && (
        <InfoHeader
          card={currentCard}
          active={active}
          phoneScreen={phoneScreen}
        />
      )}
      <ListSectionWrapper>
        {phoneScreen ? (
          renderList()
        ) : (
          <ContentWrapper active={active}>{renderList()}</ContentWrapper>
        )}
        {renderButtonsSection(currentCard.buttons)}
      </ListSectionWrapper>
    </InfoSectionWrapper>
  );
};

export default InfoSection;
